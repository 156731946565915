<template>
	<main class="layer-popup-container">
		<div class="layer-popup layer-popup-calendar">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<button type="button" class="btn-close" @click="clickClose">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body" data-fill="true">
						<h3 class="header-title">
							<i class="icon-tv"></i>
							방송편성표
						</h3>
						<v-calendar class="custom-calendar max-w-full" locale="en" :masks="masks" :attributes="attributes" data-popover="true" disable-page-swipe is-expanded @click:day="open">
							<template v-slot:day-content="{ day, attributes }" v-if="!isMobile">
								<div class="flex flex-col h-full z-10 overflow-hidden">
									<span class="day-label text-sm text-gray-900">
										<em>{{ day.day }}</em>
									</span>
									<div class="flex-grow overflow-x-auto">
										<p v-for="attr in attributes" :key="attr.key" class="vc-day-custom text-xs" :class="attr.customData.class">
											<span class="vc-day-custom__time">
												{{ attr.customData.time }}
											</span>
											<span class="vc-day-custom__title">
												{{ attr.customData.title }}
											</span>
										</p>
									</div>
								</div>
							</template>
							<!-- mobile popover -->
							<template v-slot:day-popover="{ dayTitle, attributes }" v-else>
								<div class="text-xs vc-popover-item" v-for="attr in attributes" :key="attr.key">
									<span class="vc-day-custom__time">
										{{ attr.customData.time }}
									</span>
									<span class="vc-day-custom__title">
										{{ attr.customData.title }}
									</span>
								</div>
							</template>
						</v-calendar>
						<!-- mobile -->
						<!-- <v-calendar class="custom-calendar max-w-full" locale="en" view="weekly" is-expanded v-else></v-calendar> -->
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { MUT_CLOSE_BROADCAST_CALENDAR_MODAL } from '../../store/_mut_consts';
import VCalendar from 'v-calendar';
import { ACT_GET_PROG_SCHEDULE_LIST } from '@/store/_act_consts';
import { getItems } from '@/assets/js/utils';
Vue.use(VCalendar, {});

export default {
	name: 'BroadcastCalendarModal',
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
		...mapGetters('broadcast', ['broadcastCalendar']),
		...mapGetters('common', ['isMobile']),
	},
	data() {
		const year = new Date().getFullYear();
		const month = new Date().getMonth();
		return {
			masks: {
				title: 'YYYY년 M월',
				weekdays: 'WWW',
			},
			attributes: [
				/*
				{
					key: 3,
					dot: 'red',
					customData: {
						time: '14:00',
						title: '방송제목 LIVE',
						class: 'color-red',
					},
					dates: new Date(year, month, 5),
				},
				{
					key: 4,
					dot: 'red',
					customData: {
						time: '14:00',
						title: '방송제목이 들어갑니다. 방송제목이 들어갑니다. 방송제목이 들어갑니다. ',
					},
					dates: new Date(year, month, 5),
				},
*/
			],
		};
	},
	created() {
		//console.log('BroadcastCalendarModal!!!');
	},
	mounted() {
		this.getScheduleList();
	},
	methods: {
		open(nativeEvent, event) {
			console.log('nativeEvent : ' + nativeEvent);
			console.log('event : ' + event);
		},
		clickClose() {
			this.$store.commit(`broadcast/${MUT_CLOSE_BROADCAST_CALENDAR_MODAL}`);
		},
		getScheduleList() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_PROG_SCHEDULE_LIST}`, {})
				.then((res) => {
					this.attributes.splice(0); //캘린더 데이터 리셋
					var items = getItems(res);

					for (var i = 0; i < items.length; i++) {
						// console.log('item ${i} : ' + JSON.stringify(items[i]));
						var attribute = {
							key: items[i].progScheId,
							dot: 'red',
							popover: {
								label: items[i].progScheTit,
								visibility: 'click',
							},
							customData: {
								time: items[i].progScheBgnDate.substring(11, 16),
								title: items[i].progScheTit,
							},
							dates: new Date(
								items[i].progScheBgnDate.substring(0, 4),
								items[i].progScheBgnDate.substring(5, 7) - 1, //date > month 보정 -1
								items[i].progScheBgnDate.substring(8, 10)
							),
						};

						//console.log(attribute);
						this.attributes.push(attribute);
					}
					// console.log('this.attributes : ', this.attributes);
				})
				.catch((e) => {
					//console.log(e);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.layer-popup .popup-body {
	padding: 0 clamp(15px, 4vw, 140px);
}
::-webkit-scrollbar {
	width: 0px;
}
::-webkit-scrollbar-track {
	display: none;
}

:deep(.custom-calendar.vc-container) {
	--day-border: 1px solid #d9d9d9;
	--day-border-highlight: 1px solid #b8c2cc;
	--day-width: 30px;
	--day-height: 100px;
	--weekday-bg: transparent;
	--weekday-border: 1px solid transparent;
	width: 100%;
	overflow: hidden;
	margin-bottom: 2em;
	border: none;
	border-radius: 15px;
	background-color: transparent;
	font-family: var(--font-family-base);
	// font-family: var(--font-family-nanumsquare);
	.vc-header {
		background-color: transparent;
		padding: 5px 0 20px;
	}
	.vc-arrows-container {
		width: 260px;
		left: 50%;
		margin-left: -130px;
		padding-top: 3px;
		.vc-arrow {
			&:hover {
				background-color: transparent;
			}
		}
	}
	.vc-day-popover-container {
		max-width: 100px;
		border-color: #f2f0ed;
		background-color: #f2f0ed;
		color: #645f5b;
	}
	.vc-popover-content {
		text-align: left;
		.vc-popover-item {
			display: flex;
			flex-direction: column;
			margin: 0.8em 0;
		}
		.vc-day-custom__time {
			font-weight: 700;
		}
	}
	.vc-title {
		font-size: 24px;
		font-weight: 700;
	}
	.vc-weeks {
		padding: 0;
	}
	.vc-weekday {
		padding: 10px 0;
		border-top: var(--weekday-border);
		// border-bottom: var(--day-border);
		background-color: var(--weekday-bg);
		font-weight: normal;
		color: #9a918b;
	}

	.vc-day {
		min-width: var(--day-width);
		height: var(--day-height);
		padding: 0 5px 3px 5px;
		background-color: white;
		text-align: left;
		border-bottom: var(--day-border);
		&.is-today {
			.day-label {
				em {
					border-color: #ff7300;
				}
			}
			.vc-day-content {
				border: 2px solid #ff7300;
			}
		}
		&.weekday-7,
		&.weekday-1 {
			background-color: #fae7e7;
		}
		&.is-not-in-month {
			span {
				opacity: 0.1;
			}
		}
		.day-label {
			display: block;
			text-align: right;
			padding: 0.2em 0 0;
			em {
				display: inline-flex;
				border: 2px solid transparent;
				width: 24px;
				height: 24px;
				padding: 0;
				font-size: 16px;
				font-weight: 700;
				color: #645f5b;
				border-radius: 50%;
				align-items: center;
				justify-content: center;
				line-height: 1;
			}
		}
		&.weekday-1 {
			border-left: var(--day-border);
			&.on-top {
				border-top-left-radius: 15px;
			}
			&.on-bottom {
				border-bottom: var(--day-border);
				border-bottom-left-radius: 15px;
			}
		}
		&.weekday-7 {
			border-right: var(--day-border);
			&.on-top {
				border-top-right-radius: 15px;
			}
			&.on-bottom {
				border-bottom: var(--day-border);
				border-bottom-right-radius: 15px;
			}
		}
		&.on-top {
			border-top: var(--day-border-highlight);
		}
		&:not(.on-bottom) {
			&.weekday-1 {
				border-bottom: var(--day-border-highlight);
			}
		}
		&:not(.on-right) {
			border-right: var(--day-border);
		}
	}
	.vc-day-dots {
		margin-bottom: 5px;
	}
	.vc-dots {
		display: none;
		position: absolute;
		padding-top: 10px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 20%);
	}
	.vc-dot {
		width: 7px;
		height: 7px;
		margin: 0;
	}
	.vc-day-custom {
		display: flex;
		margin-top: 5px;
		color: #645f5b;
		&.color-red {
			color: #cd0505;
		}
		&__time {
			padding-right: 3px;
			font-weight: bold;
		}
		&__title {
			overflow: hidden;
			display: -webkit-box;
			font-size: 12px;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-height: 1.2em;
			max-height: 2.4em;
		}
	}
}
.text-gray-900 {
	--text-opacity: 1;
	color: #1a202c;
	color: rgba(26, 32, 44, var(--text-opacity));
}
.text-sm {
	font-size: 0.875rem;
}
.text-xs {
	font-size: 0.75rem;
}
.rounded-sm {
	border-radius: 0.125rem;
}
.bg-red-600 {
	--bg-opacity: 1;
	background-color: #e53e3e;
	background-color: rgba(229, 62, 62, var(--bg-opacity));
}

@media screen and (max-width: 768px) {
	:deep(.custom-calendar.vc-container) {
		.vc-dots {
			display: flex;
			gap: 5px;
		}
		.vc-day {
			height: 70px;
		}
		.vc-day-custom {
			display: none;
		}
		.vc-title {
			font-size: 16px;
		}
		.vc-arrows-container {
			width: 160px;
			left: 50%;
			margin-left: -80px;
			padding-top: 3px;
		}
		.vc-weekday {
			font-size: 12px;
		}
	}
}
</style>
